<script>
import appConfig from '@/app.config';

export default {
    page: {
        title: '404 Error Page',
        meta: [{ name: 'description', content: appConfig.description }],
    },
    methods: {
        close() {
            window.close();
        },
    },
};
</script>
<template>
    <div class="account-pages my-5 pt-5">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center mb-5">
                        <h1 class="display-2 fw-bold text-danger">
                            4<i class="bx bx-buoy bx-spin display-3"></i>1
                        </h1>
                        <h4 class="text-uppercase fw-bold">
                            {{ $t('unauthorized') }}
                        </h4>
                        <div class="mt-5 text-center">
                            <button
                                class="btn btn-danger btn-lg"
                                @click="close()">
                                {{ $t('close') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
